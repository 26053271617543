import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import './CommonerDashboard.css';

const CommonerDashboard = () => {
  const { currentUser } = useAuth();
  const [requestSent, setRequestSent] = useState(false);
  const [requestReason, setRequestReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('overview');
  const [messages, setMessages] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState(0);

  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';  // Replace with your actual production API URL

  // Check if user has already sent a request
  useEffect(() => {
    const checkExistingRequest = async () => {
      try {
        const response = await fetch(`${API_URL}/check-role-request`, {
          headers: {
            'x-auth-token': localStorage.getItem('auth_token')
          }
        });
        
        if (response.ok) {
          const data = await response.json();
          if (data.hasRequest) {
            setRequestSent(true);
          }
        }
      } catch (err) {
        console.error('Error checking existing request:', err);
      }
    };
    
    checkExistingRequest();
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await fetch(`${API_URL}/messages`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setMessages(data);
        
        // Count unread messages
        const unread = data.filter(message => !message.read).length;
        setUnreadMessages(unread);
      }
    } catch (err) {
      console.error('Error fetching messages:', err);
    }
  };

  const handleAdventurerRequest = async (e) => {
    e.preventDefault();
    
    if (!requestReason.trim()) {
      setError('Please provide a reason for your request.');
      return;
    }
    
    setLoading(true);
    setError('');
    
    try {
      console.log('Sending adventurer request to:', `${API_URL}/request-role-upgrade`);
      const response = await fetch(`${API_URL}/request-role-upgrade`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify({
          userId: currentUser.id,
          requestedRole: 'adventurer',
          reason: requestReason
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to send request');
      }
      
      setRequestSent(true);
    } catch (err) {
      setError(err.message || 'An error occurred. Please try again later.');
      console.error('Error sending adventurer request:', err);
    } finally {
      setLoading(false);
    }
  };

  const markMessageAsRead = async (messageId) => {
    try {
      const response = await fetch(`${API_URL}/messages/${messageId}/read`, {
        method: 'PUT',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok) {
        // Update local state
        setMessages(messages.map(message => 
          message.id === messageId ? { ...message, read: true } : message
        ));
        
        // Update unread count
        setUnreadMessages(prev => Math.max(0, prev - 1));
      }
    } catch (err) {
      console.error('Error marking message as read:', err);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    
    // If switching to messages tab, mark all as read
    if (tab === 'messages') {
      setUnreadMessages(0);
      
      // Mark all messages as read in the backend
      messages.forEach(message => {
        if (!message.read) {
          markMessageAsRead(message.id);
        }
      });
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  return (
    <div className="commoner-dashboard">
      <div className="dashboard-tabs">
        <button 
          className={`tab-button ${activeTab === 'overview' ? 'active' : ''}`}
          onClick={() => handleTabClick('overview')}
        >
          Overview
        </button>
        <button 
          className={`tab-button ${activeTab === 'messages' ? 'active' : ''}`}
          onClick={() => handleTabClick('messages')}
        >
          Messages {unreadMessages > 0 && <span className="message-badge">{unreadMessages}</span>}
        </button>
        <button 
          className={`tab-button ${activeTab === 'links' ? 'active' : ''}`}
          onClick={() => handleTabClick('links')}
        >
          Important Links
        </button>
      </div>
      
      <div className="tab-content">
        {activeTab === 'overview' && (
          <>
            <div className="welcome-section">
              <h2>Welcome to First Veil</h2>
              <p>
                You are currently a <span className="role-highlight">Commoner</span> in our realm. 
                Commoners have limited access to the world of First Veil.
              </p>
            </div>
            
            <div className="request-adventurer-section">
              <h3>Become an Adventurer</h3>
              <p>
                Adventurers gain access to exclusive content, including:
              </p>
              <ul className="benefits-list">
                <li>Full access to the First Veil website</li>
                <li>Image galleries of campaign moments</li>
                <li>Direct links to FoundryVTT for gameplay</li>
                <li>Communication channels with other players</li>
                <li>Game Master updates and session recaps</li>
              </ul>
              
              {requestSent ? (
                <div className="request-sent">
                  <div className="request-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                      <polyline points="22 4 12 14.01 9 11.01"></polyline>
                    </svg>
                  </div>
                  <h4>Request Sent!</h4>
                  <p>
                    Your request to become an Adventurer has been sent to the Game Master.
                    You will be notified once your request has been reviewed.
                  </p>
                </div>
              ) : (
                <form className="request-form" onSubmit={handleAdventurerRequest}>
                  <div className="form-group">
                    <label htmlFor="requestReason">Why do you want to become an Adventurer?</label>
                    <textarea
                      id="requestReason"
                      value={requestReason}
                      onChange={(e) => setRequestReason(e.target.value)}
                      placeholder="Tell us about yourself and why you want to join the adventure..."
                      rows={5}
                      required
                    />
                  </div>
                  
                  {error && <div className="error-message">{error}</div>}
                  
                  <button 
                    type="submit" 
                    className="request-button"
                    disabled={loading}
                  >
                    {loading ? 'Sending Request...' : 'Request Adventurer Status'}
                  </button>
                </form>
              )}
            </div>
          </>
        )}
        
        {activeTab === 'messages' && (
          <div className="messages-tab">
            <h3>Personal Messages</h3>
            
            {messages.length > 0 ? (
              <div className="messages-list">
                {messages.map(message => (
                  <div 
                    key={message.id} 
                    className={`message-item ${!message.read ? 'unread' : ''}`}
                    onClick={() => markMessageAsRead(message.id)}
                  >
                    <div className="message-header">
                      <div className="message-from">From: {message.fromAdmin ? 'Game Master' : message.senderName}</div>
                      <div className="message-date">{formatDate(message.createdAt)}</div>
                    </div>
                    <div className="message-content">{message.content}</div>
                    {!message.read && <div className="message-badge">New</div>}
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-messages">
                <p>You have no messages yet.</p>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'links' && (
          <div className="links-tab">
            <h3>Important Links</h3>
            
            <div className="links-container">
              <div className="link-card">
                <h4>First Veil Website</h4>
                <p>Visit our main website for general information</p>
                <a 
                  href="https://firstveil.com" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="link-button"
                >
                  Visit Website
                </a>
              </div>
              
              <div className="link-card">
                <h4>Discord</h4>
                <p>Join our community Discord server</p>
                <a 
                  href="https://discord.gg/SGMCCwsd" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="link-button"
                >
                  Join Discord
                </a>
              </div>
              
              <div className="link-card">
                <h4>Adventurer Benefits</h4>
                <p>Learn more about becoming an Adventurer</p>
                <button 
                  onClick={() => handleTabClick('overview')}
                  className="link-button"
                >
                  View Benefits
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommonerDashboard; 