import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import './AdminDashboard.css';

const AdminDashboard = () => {
  const { currentUser } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [roleRequests, setRoleRequests] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [activeTab, setActiveTab] = useState('users');
  const [searchTerm, setSearchTerm] = useState('');
  const [confirmAction, setConfirmAction] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [sendingMessage, setSendingMessage] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [showMessageForm, setShowMessageForm] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';  // Replace with your actual production API URL

  useEffect(() => {
    fetchUsers();
    fetchRoleRequests();
    fetchNotifications();

    // Set up polling for new notifications
    const notificationInterval = setInterval(() => {
      fetchNotifications(true);
    }, 60000); // Check every minute

    return () => clearInterval(notificationInterval);
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    setError('');
    
    try {
      console.log('Fetching users from:', `${API_URL}/admin/users`);
      const response = await fetch(`${API_URL}/admin/users`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `Server responded with status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Users fetched successfully:', data);
      setUsers(data);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError(`Error fetching users: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const fetchRoleRequests = async () => {
    try {
      console.log('Fetching role requests from:', `${API_URL}/admin/role-requests`);
      const response = await fetch(`${API_URL}/admin/role-requests`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Error response:', errorData);
        return; // Don't set error state here to avoid overriding user fetch errors
      }
      
      const data = await response.json();
      console.log('Role requests fetched successfully:', data);
      setRoleRequests(data);
    } catch (err) {
      console.error('Error fetching role requests:', err);
      // Don't set error state here to avoid overriding user fetch errors
    }
  };

  const fetchNotifications = async (silent = false) => {
    try {
      if (!silent) setLoading(true);
      
      const response = await fetch(`${API_URL}/admin/notifications`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Error response:', errorData);
        return;
      }
      
      const data = await response.json();
      console.log('Notifications fetched successfully:', data);
      
      // Count unread notifications
      const unread = data.filter(notification => !notification.read).length;
      setUnreadNotifications(unread);
      
      setNotifications(data);
    } catch (err) {
      console.error('Error fetching notifications:', err);
    } finally {
      if (!silent) setLoading(false);
    }
  };

  const markNotificationAsRead = async (notificationId) => {
    try {
      const response = await fetch(`${API_URL}/admin/notifications/${notificationId}/read`, {
        method: 'PUT',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Error response:', errorData);
        return;
      }
      
      // Update local state
      setNotifications(notifications.map(notification => 
        notification.id === notificationId ? { ...notification, read: true } : notification
      ));
      
      // Update unread count
      setUnreadNotifications(prev => Math.max(0, prev - 1));
    } catch (err) {
      console.error('Error marking notification as read:', err);
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setShowMessageForm(false);
  };

  const handleRoleChange = async (userId, newRole) => {
    try {
      const response = await fetch(`${API_URL}/admin/users/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify({ role: newRole })
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to update user role');
      }
      
      // Update local state
      setUsers(users.map(user => 
        user.id === userId ? { ...user, role: newRole } : user
      ));
      
      if (selectedUser && selectedUser.id === userId) {
        setSelectedUser({ ...selectedUser, role: newRole });
      }
      
      // If this was a role request, refresh the requests
      fetchRoleRequests();
      setError(''); // Clear any previous errors
    } catch (err) {
      console.error('Error updating user role:', err);
      setError(`Error updating user role: ${err.message}`);
    }
  };

  const handlePasswordReset = async (userId) => {
    setConfirmAction({
      type: 'resetPassword',
      userId,
      message: 'Are you sure you want to reset this user\'s password?',
      confirmText: 'Reset Password',
      action: async () => {
        try {
          const response = await fetch(`${API_URL}/admin/users/${userId}/reset-password`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': localStorage.getItem('auth_token')
            },
            body: JSON.stringify({ newPassword: 'tempPassword123' }) // Default temporary password
          });
          
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || 'Failed to reset password');
          }
          
          setError('');
          alert('Password has been reset to: tempPassword123');
        } catch (err) {
          console.error('Error resetting password:', err);
          setError(`Error resetting password: ${err.message}`);
        } finally {
          setConfirmAction(null);
        }
      }
    });
  };

  const handleDeleteUser = async (userId) => {
    setConfirmAction({
      type: 'deleteUser',
      userId,
      message: 'Are you sure you want to delete this user? This action cannot be undone.',
      confirmText: 'Delete User',
      action: async () => {
        try {
          const response = await fetch(`${API_URL}/admin/users/${userId}`, {
            method: 'DELETE',
            headers: {
              'x-auth-token': localStorage.getItem('auth_token')
            }
          });
          
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || 'Failed to delete user');
          }
          
          // Update local state
          setUsers(users.filter(user => user.id !== userId));
          
          if (selectedUser && selectedUser.id === userId) {
            setSelectedUser(null);
          }
          
          setError('');
        } catch (err) {
          console.error('Error deleting user:', err);
          setError(`Error deleting user: ${err.message}`);
        } finally {
          setConfirmAction(null);
        }
      }
    });
  };

  const handleApproveRequest = async (requestId, userId) => {
    try {
      // First update the user's role
      await handleRoleChange(userId, 'adventurer');
      
      // Then mark the request as approved
      const response = await fetch(`${API_URL}/admin/role-requests/${requestId}/approve`, {
        method: 'PUT',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to approve request');
      }
      
      // Update local state
      setRoleRequests(roleRequests.filter(req => req.id !== requestId));
      setError(''); // Clear any previous errors
    } catch (err) {
      console.error('Error approving request:', err);
      setError(`Error approving request: ${err.message}`);
    }
  };

  const handleDenyRequest = async (requestId) => {
    try {
      const response = await fetch(`${API_URL}/admin/role-requests/${requestId}/deny`, {
        method: 'PUT',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to deny request');
      }
      
      // Update local state
      setRoleRequests(roleRequests.filter(req => req.id !== requestId));
      setError(''); // Clear any previous errors
    } catch (err) {
      console.error('Error denying request:', err);
      setError(`Error denying request: ${err.message}`);
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    
    if (!selectedUser) {
      setMessageError('No user selected');
      return;
    }
    
    if (!messageText.trim()) {
      setMessageError('Message cannot be empty');
      return;
    }
    
    setSendingMessage(true);
    setMessageError('');
    
    try {
      const response = await fetch(`${API_URL}/admin/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify({
          userId: selectedUser.id,
          message: messageText,
          fromAdmin: true
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to send message');
      }
      
      // Clear the message form
      setMessageText('');
      setShowMessageForm(false);
      alert(`Message sent to ${selectedUser.displayName}`);
    } catch (err) {
      console.error('Error sending message:', err);
      setMessageError(`Error sending message: ${err.message}`);
    } finally {
      setSendingMessage(false);
    }
  };

  const handleRetry = () => {
    setError('');
    fetchUsers();
    fetchRoleRequests();
    fetchNotifications();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  // Filter users based on search term
  const filteredUsers = users.filter(user => 
    user.displayName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.role?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="admin-dashboard">
      <div className="dashboard-tabs">
        <button 
          className={`tab-button ${activeTab === 'users' ? 'active' : ''}`}
          onClick={() => setActiveTab('users')}
        >
          User Management
        </button>
        <button 
          className={`tab-button ${activeTab === 'requests' ? 'active' : ''}`}
          onClick={() => setActiveTab('requests')}
        >
          Role Requests {roleRequests.length > 0 && <span className="request-badge">{roleRequests.length}</span>}
        </button>
        <button 
          className={`tab-button ${activeTab === 'notifications' ? 'active' : ''}`}
          onClick={() => setActiveTab('notifications')}
        >
          Notifications {unreadNotifications > 0 && <span className="request-badge">{unreadNotifications}</span>}
        </button>
        <button 
          className={`tab-button ${activeTab === 'links' ? 'active' : ''}`}
          onClick={() => setActiveTab('links')}
        >
          Important Links
        </button>
      </div>
      
      {error && (
        <div className="admin-error">
          <p>{error}</p>
          <button className="retry-button" onClick={handleRetry}>Retry</button>
        </div>
      )}
      
      <div className="tab-content">
        {activeTab === 'users' && (
          <div className="users-tab">
            <div className="users-list-container">
              <div className="search-bar">
                <input
                  type="text"
                  placeholder="Search users..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              
              {loading ? (
                <div className="loading-users">
                  <div className="loading-spinner"></div>
                  <p>Loading users...</p>
                </div>
              ) : (
                <div className="users-list">
                  {filteredUsers.length > 0 ? (
                    filteredUsers.map(user => (
                      <div 
                        key={user.id} 
                        className={`user-item ${selectedUser?.id === user.id ? 'selected' : ''}`}
                        onClick={() => handleUserSelect(user)}
                      >
                        <div className="user-avatar">
                          {user.avatar ? (
                            <img src={user.avatar} alt={user.displayName} />
                          ) : (
                            <div className="avatar-placeholder">
                              {user.displayName?.charAt(0).toUpperCase() || 'U'}
                            </div>
                          )}
                        </div>
                        <div className="user-info">
                          <div className="user-name">{user.displayName}</div>
                          <div className="user-email">{user.email}</div>
                        </div>
                        <div className={`user-role role-${user.role}`}>
                          {user.role}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-users">No users found</div>
                  )}
                </div>
              )}
            </div>
            
            <div className="user-details">
              {selectedUser ? (
                <>
                  <h3>User Details</h3>
                  <div className="detail-item">
                    <span className="detail-label">ID:</span>
                    <span className="detail-value">{selectedUser.id}</span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">Name:</span>
                    <span className="detail-value">{selectedUser.displayName}</span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">Email:</span>
                    <span className="detail-value">{selectedUser.email}</span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">Role:</span>
                    <div className="role-selector">
                      <select 
                        value={selectedUser.role}
                        onChange={(e) => handleRoleChange(selectedUser.id, e.target.value)}
                      >
                        <option value="commoner">Commoner</option>
                        <option value="adventurer">Adventurer</option>
                        <option value="admin">Admin</option>
                      </select>
                    </div>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">Created:</span>
                    <span className="detail-value">{formatDate(selectedUser.createdAt)}</span>
                  </div>
                  
                  <div className="user-actions">
                    <button 
                      className="action-button reset-password"
                      onClick={() => handlePasswordReset(selectedUser.id)}
                    >
                      Reset Password
                    </button>
                    <button 
                      className="action-button delete-user"
                      onClick={() => handleDeleteUser(selectedUser.id)}
                    >
                      Delete User
                    </button>
                  </div>
                  
                  <div className="message-section">
                    {showMessageForm ? (
                      <form onSubmit={handleSendMessage} className="message-form">
                        <h4>Send Message to {selectedUser.displayName}</h4>
                        {messageError && <div className="message-error">{messageError}</div>}
                        <textarea
                          value={messageText}
                          onChange={(e) => setMessageText(e.target.value)}
                          placeholder="Enter your message here..."
                          rows={4}
                          required
                        />
                        <div className="message-actions">
                          <button 
                            type="submit" 
                            className="send-message-btn"
                            disabled={sendingMessage}
                          >
                            {sendingMessage ? 'Sending...' : 'Send Message'}
                          </button>
                          <button 
                            type="button" 
                            className="cancel-message-btn"
                            onClick={() => setShowMessageForm(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    ) : (
                      <button 
                        className="action-button message-user"
                        onClick={() => setShowMessageForm(true)}
                      >
                        Send Message
                      </button>
                    )}
                  </div>
                </>
              ) : (
                <div className="no-selection">
                  <p>Select a user to view details</p>
                </div>
              )}
            </div>
          </div>
        )}
        
        {activeTab === 'requests' && (
          <div className="requests-tab">
            <h3>Adventurer Role Requests</h3>
            
            {loading ? (
              <div className="loading-requests">
                <div className="loading-spinner"></div>
                <p>Loading requests...</p>
              </div>
            ) : roleRequests.length > 0 ? (
              <div className="requests-list">
                {roleRequests.map(request => (
                  <div key={request.id} className="request-item">
                    <div className="request-header">
                      <div className="requester-info">
                        <span className="requester-name">{request.userName}</span>
                        <span className="requester-email">{request.userEmail}</span>
                      </div>
                      <div className="request-date">{formatDate(request.createdAt)}</div>
                    </div>
                    
                    <div className="request-reason">
                      <h4>Reason for Request:</h4>
                      <p>{request.reason}</p>
                    </div>
                    
                    <div className="request-actions">
                      <button 
                        className="action-button approve"
                        onClick={() => handleApproveRequest(request.id, request.userId)}
                      >
                        Approve
                      </button>
                      <button 
                        className="action-button deny"
                        onClick={() => handleDenyRequest(request.id)}
                      >
                        Deny
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-requests">
                <p>No pending role requests</p>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'notifications' && (
          <div className="notifications-tab">
            <h3>System Notifications</h3>
            
            {loading ? (
              <div className="loading-notifications">
                <div className="loading-spinner"></div>
                <p>Loading notifications...</p>
              </div>
            ) : notifications.length > 0 ? (
              <div className="notifications-list">
                {notifications.map(notification => (
                  <div 
                    key={notification.id} 
                    className={`notification-item ${!notification.read ? 'unread' : ''}`}
                    onClick={() => markNotificationAsRead(notification.id)}
                  >
                    <div className="notification-header">
                      <div className="notification-type">{notification.type}</div>
                      <div className="notification-date">{formatDate(notification.createdAt)}</div>
                    </div>
                    <div className="notification-content">
                      {notification.content}
                    </div>
                    {!notification.read && (
                      <div className="notification-badge">New</div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-notifications">
                <p>No notifications</p>
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'links' && (
          <div className="links-tab">
            <h3>Important Links</h3>
            
            <div className="links-container">
              <div className="link-card">
                <h4>FoundryVTT</h4>
                <p>Access the virtual tabletop for your campaigns</p>
                <a 
                  href="http://47.7.21.116:30000/join" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="link-button"
                >
                  Open FoundryVTT
                </a>
              </div>
              
              <div className="link-card">
                <h4>Discord</h4>
                <p>Join our community Discord server</p>
                <a 
                  href="https://discord.gg/SGMCCwsd" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="link-button"
                >
                  Join Discord
                </a>
              </div>
              
              <div className="link-card">
                <h4>Admin Documentation</h4>
                <p>Access admin guides and documentation</p>
                <a 
                  href="#" 
                  onClick={() => alert('Documentation coming soon!')}
                  className="link-button"
                >
                  View Documentation
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      
      {confirmAction && (
        <div className="confirm-modal">
          <div className="confirm-content">
            <h3>Confirm Action</h3>
            <p>{confirmAction.message}</p>
            <div className="confirm-buttons">
              <button 
                className="confirm-button"
                onClick={confirmAction.action}
              >
                {confirmAction.confirmText}
              </button>
              <button 
                className="cancel-button"
                onClick={() => setConfirmAction(null)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard; 